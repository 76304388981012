import React from 'react'

const Error = () => {
  return (
    <div className='flex justify-center items-center min-h-[94vh] text-3xl text-white'>
        Error - 404 Not Found
    </div>
  )
}

export default Error