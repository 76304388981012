import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import getAvgRating from '../../utils/avgRating'
import { useEffect } from 'react'
import RatingStars from '../../Common/RatingStars'
 
const CourseCard = ({course,Height}) => {
    const [avgReviewCount,setAvgReviewCount] = useState(0)

    useEffect(()=>{
        const count = getAvgRating(course?.ratingAndReviews)
        setAvgReviewCount(count)
    },[course])
  return (
    <div className="text-richblack-5">
        <Link to={`/courses/${course._id}`}>
            <div>
                <div className="rounded-lg">
                    <img src={course?.thumbnail} className={`${Height} w-full rounded-xl object-cover`}/>
                </div>
                <div className="flex flex-col gap-2 px-1 py-3">
                    <p className="text-xl text-richblack-5">{course.courseName}</p>
                    <p className="capitalize text-sm text-richblack-50">{course?.instructor?.firstName} {course?.instructor?.lastName}</p>
                    <div className='flex items-center gap-2'>
                        <span>{avgReviewCount || 0}</span>
                        <RatingStars Review_Count={avgReviewCount}/>
                        <span className="text-richblack-400">{course.ratingAndReviews.length} Ratings</span>
                    </div>
                    <p className="text-xl text-richblack-5">Rs {course?.price}</p>
                </div>
            </div>
        </Link>
       
    </div>
  )
}

export default CourseCard